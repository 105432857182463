/* Basic Styling */
.header-navbar {
  transition: background-color 0.4s ease, color 0.4s ease;
  padding: 15px 30px;
  background-color: transparent; /* Keep initial transparent */
  z-index: 1000 !important; /* Ensure the navbar stays on top */
  position: relative;
}

.imagestyle{
  width :100px;
  height: 90px;
  object-fit: contain;
}

.header-navbar.scrolled {
  background-color: white; /* Solid background when scrolled */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  transition: color 0.3s ease;
}

.boldname{
  font-weight: bold !important;

}

.header-navbar.scrolled .logo {
  color: black;
  font-weight: bold;
}

.carouselheight{

  height: 200px;
  // background-color: #84ff00;
}

.nav-link {
  font-size: 1rem;
  color: white;
  padding: 10px 15px;
  transition: color 0.3s ease;
  display: flex;
  font-weight: bold;
  align-items: center; /* Align vertically */
}



.header-navbar.scrolled .nav-link {
  color: black;
  font-weight: bold;
  
}

.nav-link:hover {
  color: #007bff;
}

/* Flexbox for alignment */
.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Dropdown Hover Styling */
.navbar-nav .product-dropdown {
  position: relative;
}

.navbar-nav .product-dropdown .dropdown-menu {
  background-color: white; /* Dropdown background color */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  z-index: 1100; /* Ensure dropdown stays above other elements */
}

.product-dropdown.show .dropdown-menu {
  display: block; /* Show dropdown when active */
}

/* Ensure dropdown is above other content */
.dropdown-menu {
  z-index: 9999; 
}

// .profilenamee{
//   width: 120px;
//   height: 30px ;
//   background-color: #0f7e8d !important;
//   border-radius: 50px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

//   justify-content: center;
//   align-items: center;
//   display: flex;
// }

/* Responsive Design */
@media (max-width: 992px) {

  .white{
    background-color: aliceblue;
  }
  .header-navbar {
    padding: 10px 20px;
  }

  .nav-link {
    color: black;
  font-weight: bold;
    padding: 8px 10px;
    font-size: 1.0rem !important; /* Slightly smaller font size */
  }

  .navbar-nav {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align to start */
  }

  .navbar-nav .product-dropdown .dropdown-menu {
    left: 0; /* Align dropdown to the left */
    right: auto; /* Reset right alignment */
    width: 100%; /* Full width for dropdown */
  }
}

@media (max-width: 768px) {
  .nav-link {
    padding: 10px;
    margin-top: 10px;
   text-align: left !important;
    width: 100%; /* Full width for easier touch interaction */
    // background-color: #007bff !important;
  }

  .logo {
    font-size: 1.2rem;
  }
}



.hovereffect {
  color: black !important;
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; /* Add transitions */
  position: relative; /* For the underline effect */
}

.hovereffect:hover {
  color: #01536f !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add text shadow */
  transform: scale(1.05); /* Slightly increase the size */
}

/* Optional: Add an underline effect */
.hovereffect::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 3px;
  right: 0;
  background: #580fb7;
  transition: width 0.3s ease;
}

.hovereffect:hover::after {
  
  width: 100%;
  left: 0;
  background: #580fb7;
}


.nav-link.active {
  color: rgb(8, 8, 127) !important; /* Make the active link blue */
  font-weight: bold; /* Optionally, make it bold */
}




.hovereffecthead {
   color: #115fb2 !important; 
  transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease; /* Add transitions */
  position: relative; /* For the underline effect */
}

.hovereffecthead:hover {
  color: #580fb7 !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Add text shadow */
  transform: scale(1.05); /* Slightly increase the size */
}

/* Optional: Add an underline effect */
.hovereffecthead::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  margin-top: 5px;
  right: 0;
  background: #580fb7;
  transition: width 0.3s ease;
}

.hovereffecthead:hover::after {
  width: 70%;
  left: 0;
  background: #580fb7;
}

.rounded-circleprofile{

  border-radius: 50px;
  width: 50px;
  height: 50px;
  // background-color: aqua;

}