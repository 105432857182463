/* .hero-sectionhow {
  background: url("../../Components/images/transportlogistic.jpg") no-repeat cover;
  
  color: white;
  padding: 100px 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100vh;
} */

.hero-sectionhow {
  background: url("../../Pages/images/transport.jpg") no-repeat center center / cover;
  color: white;
  padding: 100px 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 70vh;
  text-align: center;
  
}

.bgimagebuy {
  background: url("../../Components/images/transportlogistic.jpg") no-repeat
    center center / cover;
  color: white;
  padding: 100px 20px;
  text-align: center;
}
.bgimagebuyrecevie {
  background: url("../../Pages/images/revenue.jpg") no-repeat center center /
    cover;
  color: white;
  padding: 100px 20px;
  text-align: center;
}

.how-to-work-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.hero-contentwork {
  margin-top: 100px;
  max-width: 600px;
  /* // margin: auto;? */
  padding: 20px;
  height: 200px;
  background: rgba(0, 0, 0, 0.5);
}

.sectionone {
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.sectionone:hover {
  transform: scale(1.05); /* Hover zoom effect */
}

.background-imageone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(70%);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  max-width: 500px;
}

.tabs {
  display: flex;
  overflow-x: auto;
  justify-content: center; /* Center the tabs */
  align-items: center;
  padding: 10px 0;
  white-space: nowrap;
}

.tab {
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  flex-shrink: 0;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.tab.active {
  border-bottom: 2px solid blue;
  font-weight: bold;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  

  .tabs {
    display: flex;
    overflow-x: auto;
    justify-content: start; /* Center the tabs */
    align-items: center;
    padding: 10px 0;
    white-space: nowrap;
  }
  .tab {
    padding: 8px 10px; /* Adjust padding for smaller screens */
    margin: 0 4px; /* Reduce margins */
  }
}


.asset-details {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 50px;
}

.left-content {
  text-align: left;
}

.left-contentmob {
  text-align: no;
}

.left-content h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.left-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.left-content ul {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
}

/* .cta-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
} */

.right-content {
  width: 50%;
}
.right-contentmob {
  width: 95%;
  justify-content: center;
  align-items: center;
  
  
  
}

.carousel-image {
  width: 100%;
  height: 350px;
  border-radius: 15px;
}
.bgcolormain {
  background-color: rgb(39, 16, 192);
}

.free-consultation {
  background-color: #71b6b4;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  padding: 40px; /* Full height for vertical centering */
}

.free-consultation h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center; /* Ensure text is centered */
}

.consult-button {
  padding: 15px 30px;
  background-color: white;
  color: #0033cc;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
  display: inline-block; /* Removes the flex styling from button */
}

.h2style {
  text-align: center;
  /* width:1150px; */
  /* background-color: aliceblue; */
}

.benefit-card {
  background-color: #f8f9fa; /* Light background for cards */
  transition: transform 0.2s; /* Smooth scaling effect */
}

.benefit-card:hover {
  transform: scale(1.05); /* Scale up on hover */
}
.info-card {
  background-color: #f8f9fa; /* Light background for info cards */
  transition: transform 0.2s; /* Smooth scaling effect */
}

.info-card:hover {
  transform: scale(1.05); /* Scale up on hover */
}

h4 {
  color: #007bff; /* Primary color for headings */
}

.btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}




/* Define styles for screens 1024px to 1440px wide */
@media (min-width: 1024px) and (max-width: 1440px) {
	.sizemedia {
	  font-size: 18px;
	}
  
	.header {
	  font-size: 24px;
	}
  }
  


  @media (min-width: 1024px) and (max-width: 1440px) {
	.sizemedia {
	  font-size: 18px;
	}
  
	.header {
	  font-size: 24px;
	}
  }




  .timeline-heading {
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    justify-content: center;
    align-self: center;
    display: inline-block;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .timeline-heading:hover {
    color: #3498db; /* Changes text color on hover */
  }
  
  .timeline-heading::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background: #3498db;
    left: 0;
    bottom: -5px;
    transition: width 0.3s ease;
  }
  
  .timeline-heading:hover::after {
    width: 100%; /* Grows underline on hover */
  }
  
  
  .timeline-list {
    list-style: none;
    padding: 0;
    margin: 0;
    /* border-left: 3px solid #3498db; Timeline vertical line */
  }
  
  .timeline-list-item {
    display: flex;
    align-items: flex-start;
    /* padding: 15px 0; */
    position: relative;
  }
  
  .timeline-list-item::before {
    /* content: "▶"; */
    color: #045890;
    font-size: 1.5rem;
    position: absolute;
    left: -1.6rem; /* Adjust to align arrow */
    top: 0;
  }
  
  .timeline-icon {
    font-size: 2rem;
    color: #034069;
    /* margin-right: 15px; */
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .timeline-text {
    margin-left: 10px;
    margin-top: 15px;
  }
  
  .timeline-step-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #034069;
    cursor: pointer;
  }
  
  .timeline-step-description {
    font-size: 12px;
    cursor: pointer;


    color: #555;
  }
  
  
  .video-container {
    position: relative;
    cursor: pointer;
  }
  
  .video-background {
    width: 100%;
    border-radius: 15px;
  }
  
  .play-button-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 20px;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  
  .play-button-overlay:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  

