
.ezy__portfolio1 {
	/* Bootstrap variables */
	--bs-body-color: #373572;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-popup-bg-rgb: 255, 255, 255;

	background-color: var(--bs-body-bg);
	overflow: hidden;
	padding: 60px 0;
}

@media (min-width: 768px) {
	.ezy__portfolio1 {
		padding: 100px 0;
	}
}

/* Gray Block Style */
.gray .ezy__portfolio1,
.ezy__portfolio1.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-popup-bg-rgb: 246, 246, 246;
}

/* Dark Gray Block Style */
.dark-gray .ezy__portfolio1,
.ezy__portfolio1.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);

	/* Easy Frontend variables */
	--ezy-popup-bg-rgb: 30, 39, 53;
}

/* Dark Block Style */
.dark .ezy__portfolio1,
.ezy__portfolio1.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);

	/* Easy Frontend variables */
	--ezy-popup-bg-rgb: 11, 23, 39;
}

.ezy__portfolio1-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

@media (min-width: 768px) {
	.ezy__portfolio1-heading {
		font-size: 45px;
		line-height: 45px;
	}
}

.ezy__portfolio1-sub-heading {
	font-size: 16px;
	line-height: 22px;
	color: var(--bs-body-color);
}

.ezy__portfolio1-item {
	border-radius: 12px;
	overflow: hidden;
}

.ezy__portfolio1-content {
	position: absolute;
	left: 16px;
	right: 16px;
	bottom: 16px;
	border-radius: 12px;
	background-color: rgba(var(--ezy-popup-bg-rgb), 0.7);
	backdrop-filter: blur(4px) saturate(200%);
	transform: translateY(20px);
	opacity: 0;
	transition: all 0.25s ease-in-out;
}

.ezy__portfolio1-content * {
	color: var(--bs-body-color);
}

.ezy__portfolio1-item:hover .ezy__portfolio1-content,
.ezy__portfolio1-item.active .ezy__portfolio1-content {
	transform: translateY(0);
	opacity: 1;
}

.ezy__portfolio1-btn-filter {
	padding: 7px 20px;
	border-color: var(--ezy-theme-color);
	color: var(--ezy-theme-color);
}

.ezy__portfolio1-btn-filter:hover,
.ezy__portfolio1-btn-filter.active {
	background-color: var(--ezy-theme-color);
	color: #fff;
}
