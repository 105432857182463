.services {
    padding: 60px 20px;
    text-align: center;
    background-color: #f4f8fb;
  
    h2 {
      font-size: 2.5rem;
      color: #333;
      margin-bottom: 40px;
    }
  
    .service-list {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
    }
  
    .service-card {
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
      max-width: 350px;
  
      &:hover {
        transform: translateY(-10px);
      }
  
      img {
        width: 100%;
        height: auto;
      }
  
      .service-content {
        padding: 20px;
        
        h3 {
          font-size: 1.5rem;
          color: #007bff;
          margin-bottom: 15px;
        }
  
        p {
          font-size: 1rem;
          color: #555;
          line-height: 1.5;
        }
      }
    }
  }
  