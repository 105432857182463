
.ezy__signup10 {
	/* Bootstrap variables */
	--bs-body-color: #333b7b;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-form-card-bg: #ffffff;
	--ezy-form-card-shadow: 6px 0px 118px rgba(0, 0, 0, 0.08);

	background-color: var(--bs-body-bg);
	padding: 50px 0;
	overflow: hidden;
}

/* Gray Block Style */
.gray .ezy__signup10,
.ezy__signup10.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-form-card-bg: #f6f6f6;
	--ezy-form-card-shadow: 6px 0px 118px rgba(26, 26, 26, 0.08);
}

/* Dark Gray Block Style */
.dark-gray .ezy__signup10,
.ezy__signup10.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
	--bs-dark-rgb: 255, 255, 255;

	/* Easy Frontend variables */
	--ezy-form-card-bg: #2d3947;
	--ezy-form-card-shadow: none;
}

/* Dark Block Style */
.dark .ezy__signup10,
.ezy__signup10.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);
	--bs-dark-rgb: 255, 255, 255;

	/* Easy Frontend variables */
	--ezy-form-card-bg: #162231;
	--ezy-form-card-shadow: none;
}

.ezy__signup10 .container {
	min-height: 100vh;
}

.ezy__signup10-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

.ezy__signup10-bg-holder {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 150px;
	width: 100%;
	border-radius: 30px;
}

@media (min-width: 768px) {
	.ezy__signup10-bg-holder {
		width: 200%;
	}
}

@media (min-width: 992px) {
	.ezy__signup10-bg-holder {
		width: 150%;
	}
}

.ezy__signup10-form-card {
	background-color: var(--ezy-form-card-bg);
	border: none;
	box-shadow: var(--ezy-form-card-shadow);
	border-radius: 15px;
}

.ezy__signup10-form-card *:not(button, button *) {
	color: var(--bs-body-color);
}

.ezy__signup10 .form-control,
.ezy__signup10 .form-select {
	min-height: 48px;
	line-height: 40px;
	border-color: transparent;
	background-color: rgba(163, 190, 241, 0.14);
	border-radius: 10px;
	color: var(--bs-body-color);
}

.ezy__signup10 .form-control:focus {
	border-color: var(--ezy-theme-color);
	box-shadow: none;
}

.ezy__signup10-btn-submit {
	padding: 12px 30px;
	background-color: #333b7b;
	color: #ffffff;
}

.ezy__signup10-btn-submit:hover {
	color: #ffffff;
}

.ezy__signup10-btn {
	padding: 12px 30px;
}

.ezy__signup10-btn,
.ezy__signup10-btn * {
	color: #ffffff;
}

.ezy__signup10-btn:hover {
	color: #ffffff;
}

.ezy__signup10-or-separator {
	position: relative;
}

.ezy__signup10-or-separator hr {
	border-color: var(--bs-body-color);
	opacity: 0.15;
}

.ezy__signup10-or-separator span {
	background-color: var(--ezy-form-card-bg);
	color: var(--bs-body-color);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	opacity: 0.8;
}


.secondary{
    border-color: none;
    background-color: rgba(163, 190, 241, 0.14);
}


.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background-color: rgba(163, 190, 241, 0.14);
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100%;
    outline: none !important;
}





/* .ezy__signup10 {
    position: relative;
    overflow: hidden;
  }
  
  .ezy__signup10-bg-holder {
    transition: transform 0.6s ease;
  }
  
  .form-container {
    position: absolute;
    width: 100%;
    transition: transform 0.6s ease;
  }
  
  .form-container.sign-in-active {
    transform: translateX(100%);
  }
  
  .image-container {
    position: absolute;
    width: 100%;
    transition: transform 0.6s ease;
  }
  
  .image-container.sign-in-active {
    transform: translateX(-100%);
  }
   */

   .underline-link {
	text-decoration: none !important; /* Remove default underline */
	color: #333b7b !important; /* Change to your desired color */
  }
  
  .underline-link:hover {
	text-decoration: underline !important; /* Add underline on hover */
	color:  #333b7b !important; /* Change color on hover if desired */
  }
  