.products-page {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
  
    .hero-section {
      background: url("../images/transport.jpg") no-repeat center center;
      background-size: cover;
      padding: 100px 20px;
      text-align: center;
      justify-content: center;
      display: flex;
      color: white;
  
      .hero-contentprdt {
        max-width: 600px;
        // margin: auto;
        padding: 20px;
        height: 200px;
        background: rgba(0, 0, 0, 0.5);
      }
    }
  
    .tab-navigation {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      // padding-bottom: 0px;
  
      .tab-item {
        padding: 15px 30px;
        margin: 0 10px;
        background-color: #004b87;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &.active {
          background-color: #ffa500;
        }
  
        &:hover {
          background-color: #ffa500;
        }
      }
    }
  
  }

  .product-list {
    display: grid;
    gap: 10px;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
  }
  
  .product-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    min-height: 400px; /* Set consistent minimum height */
    align-items: center;
  }
  
  .prdt-img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  
  .textstyle {
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
  }
  
  .price-container {
    font-family: Arial, sans-serif;
    font-size: 1em;
    color: #2A9D8F;
    font-weight: bold;
    padding: 5px 0;
    border-radius: 8px;
    display: inline-block;
  }
  
  .price-label {
    font-size: 1em;
    color: #555;
    font-weight: bold;
  }
  
  .cta-buttonu {
    background-color: #ffa500;
    color: #fff;
    padding: 0px 0px ;
    // border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .buynow {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  
  @media (max-width: 1200px) {
    .product-list {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .product-list {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .textstyle {
      font-size: 16px;
    }
  }
  
  @media (max-width: 576px) {
    .product-list {
      grid-template-columns: 1fr;
    }
  
    .textstyle, .price-label {
      font-size: 16px;
    }
    
  }
  