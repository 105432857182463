@import url("https://fonts.googleapis.com/css?family=Titillium+Web:400,700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}



.carousell {
  min-height: 90vh;
  position: relative;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  width: 100%;
}

.progress-bar__fill {
  width: 0;
  height: inherit;
  background: #c20000;
  transition: all 0.16s;
}

.progress-bar--primary {
  z-index: 2;
}

.main-post-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slides {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.main-post {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.main-post__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-post__image img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.main-post__content {
  position: absolute;
  top: 40%;
  left: 4%;
  transform: translateY(-40%);
  color: #fff;
  width: 90%;
}

.main-post__tag-wrapper {
  margin: 0;
  display: inline-flex;
  overflow: hidden;
}

.main-post__tag {
  font-size: 0.95em;
  background: #c20000;
  padding: 6px 18px;
}

.main-post__title {
  font-weight: 700;
  font-size: 1.95em;
  line-height: 1.25;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.main-post__link {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  /* display: inline-flex; */
  align-items: center;
}

.main-post__link-text {
  font-size: 0.9em;
}

.main-post--active {
  top: 0;
  z-index: 1;
  transition: top 0.9s 0.4s ease-out;
}

.main-post--not-active {
  top: 100%;
  z-index: 0;
  transition: top 0.75s 2s;
}

.posts-wrapper {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  column-gap: 14px;
  position: absolute;
  bottom: 0;
  max-width: 95%;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1;
  
}


.custom-height {
	height: 300px !important; /* Replace with desired height */
	overflow-y: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
  }
  
  .custom-height::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
  }
.post {
  background: rgba(14, 13, 14, 0.6);
  /* opacity: 0.3; */
  color: #fff;
  position: relative;
  padding: 16px 20px;
  transition: opacity 0.2s linear;
}

.post--active {
  opacity: 1;
  background: rgba(14, 13, 14, 0.75);
  pointer-events: none;
}

.hide-on-mobile {
  display: none;
}

@media screen and (min-width: 768px) {
  .hide-on-mobile {
    display: block;
  }

  

}
