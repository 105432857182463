.ezy__service14 {
	/* Bootstrap variables */
	--bs-body-color: #252d39;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-item-color: #23262f;
	--ezy-item-bg: #ffffff;
	--ezy-popup-color: #fff;

	background-color: var(--bs-body-bg);
	overflow: hidden;
	padding: 60px 0;
}

@media (min-width: 768px) {
	.ezy__service14 {
		padding: 100px 0;
	}
}

/* Gray Block Style */
.gray .ezy__service14,
.ezy__service14.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);
}

/* Dark Gray Block Style */
.dark-gray .ezy__service14,
.ezy__service14.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);
}

/* Dark Block Style */
.dark .ezy__service14,
.ezy__service14.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(22, 34, 49);
}

.ezy__service14-heading {
	font-weight: bold;
	font-size: 25px;
	line-height: 25px;
	color: var(--bs-body-color);
}

@media (min-width: 768px) {
	.ezy__service14-heading {
		font-size: 45px;
		line-height: 45px;
	}
}

.ezy__service14-sub-heading {
	font-size: 18px;
	line-height: 25px;
	color: var(--bs-body-color);
	opacity: 0.8;
}

.ezy__service14-card {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: background-color 0.15s ease-in-out;
	height: 100%;
}

.ezy__service14-popup-card {
	border-radius: 20px;
	color: var(--ezy-popup-color);
	transform: scale(0.9);
	background-color: rgba(#060606, 0.5);
	backdrop-filter: blur(8px);
	opacity: 0;
	transition: box-shadow 0.25s ease-in-out, opacity 0.25s ease-in-out,
		transform 0.25s ease-in-out;
}

.ezy__service14-card:hover .ezy__service14-popup-card,
.ezy__service14-card.active .ezy__service14-popup-card {
	transform: scale(1);
	opacity: 1;
}

.ezy__service14-title {
	color: var(--ezy-popup-color);
}

.ezy__service14-content {
	color: var(--ezy-popup-color);
	opacity: 0.8;
	height: 150px; /* Set the desired height */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	// -webkit-line-clamp: 3; 
	-webkit-box-orient: vertical;
}
