.agreement-page {
    margin-top: 20px;
  }
  
  .agreement-page h4 {
    margin-bottom: 20px;
  }
  
  iframe {
    border-radius: 5px;
  }
  
  .button-custom {
    padding: 10px 20px;
    font-size: 1.1rem;
  }
  