
.ezy__epqoverview2 {
	/* Bootstrap variables */
	--bs-body-color: #cadf11;
	--bs-body-bg: rgb(0, 0, 0);

	/* Easy Frontend variables */
	/* --ezy-theme-color: rgb(13, 110, 253); */
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-secondary-color-rgb: 237, 237, 237;
	--ezy-button-color: #111111;

	color: var(--bs-body-color);
}

/* Gray Block Style */
.gray .ezy__epqoverview2,
.ezy__epqoverview2.gray {
	/* Bootstrap variables */
	/* --bs-body-bg: rgb(44, 27, 27); */

	/* Easy Frontend variables */
	/* --ezy-secondary-color-rgb: 225, 221, 221; */
}

/* Dark Gray Block Style */
.dark-gray .ezy__epqoverview2,
.ezy__epqoverview2.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	/* --bs-body-bg: rgb(30, 39, 53); */

	/* Easy Frontend variables */
	/* --ezy-secondary-color-rgb: 38, 63, 99; */
}

.btnbut{
	background-color: #111111;
	width:50%;
}

/* Dark Block Style */
.dark .ezy__epqoverview2,
.ezy__epqoverview2.dark {
	/* Bootstrap variables */
	--bs-body-color: #3d2424;
	--bs-body-bg: rgb(247, 251, 248);

	/* Easy Frontend variables */
	--ezy-secondary-color-rgb: 38, 63, 99;
}

.ezy__epqoverview2-heading {
	font-size: 24px;
	line-height: 25px;
	color: var(--bs-body-color);
}

@media (min-width: 768px) {
	.ezy__epqoverview2-heading {
		font-size: 22px;
		line-height: 30px;
	}

	

  
}

section.ezy__epqoverview2,
.ezy__epqoverview2 .modal-content {
	/* background-color: var(--bs-body-bg); */
}

.ezy__epqoverview2 .btn-close {
	z-index: 11;
	color: var(--bs-body-color);
	background-image: none;
}

.ezy__epqoverview2-price {
	font-size: 24px;
	font-weight: bold;
	color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epqoverview2-title {
	font-size: 15px;
	color: var(--bs-body-color);
}

.ezy__epqoverview2-qty {
	width: 180px;
}

.ezy__epqoverview2-qty .btn {
	font-size: 20px;
	font-weight: bold;
	color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epqoverview2-qty .btn:hover {
	background-color: rgb(var(--ezy-secondary-color-rgb));
}

.ezy__epqoverview2-qty .btn:first-child {
	border-radius: 50rem 0 0 50rem !important;
}

.ezy__epqoverview2-qty .btn:last-child {
	border-radius: 0 50rem 50rem 0 !important;
}

.ezy__epqoverview2-qty .form-control {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	background-color: transparent;
	color: var(--bs-body-color);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}

.ezy__epqoverview2-qty .btn,
.ezy__epqoverview2-qty .form-control {
	border-color: rgb(var(--ezy-secondary-color-rgb));
	height: 48px;
}

.ezy__epqoverview2-radios .btn {
	border-color: rgb(var(--ezy-secondary-color-rgb));
	background-color: rgba(var(--ezy-secondary-color-rgb), 0.7);
	color: var(--bs-body-color);
	font-size: 13px;
	padding: 3px 17px;
}

.ezy__epqoverview2-radios .btn:hover {
	background-color: rgba(var(--ezy-secondary-color-rgb), 1);
}

.ezy__epqoverview2-radios .btn-check:checked + .btn {
	background-color: rgb(var(--ezy-secondary-color-rgb));
	color: var(--bs-body-color);
	border-color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epqoverview2-btn {
	font-size: 14px;
	border-color: rgb(var(--ezy-theme-color-rgb));
	background-color: rgb(var(--ezy-theme-color-rgb));
	color: var(--ezy-button-color);
	height: 42px;
	min-width: 42px;
}

.ezy__epqoverview2-btn:hover {
	background-color: rgba(var(--ezy-theme-color-rgb), 0.9);
	color: var(--ezy-button-color);
}

.ezy__epqoverview2-share {
	color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epqoverview2-share:hover {
	background-color: rgba(var(--ezy-theme-color-rgb), 0.1);
	color: rgb(var(--ezy-theme-color-rgb));
}
/* Active thumbnail */
.active-thumbnail img {
	border: 3px solid rgba(0, 221, 255, 0.119); /* Set border to yellow */
	object-fit: cover;
}


.rowimage{
    display: flex;
	
	
}

.imagesidestyle{
	   width: 200px;
    height: 100px;

}

.custom-heightselect {
	height: 400px; /* Replace with desired height */
	overflow-y: scroll;
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE 10+ */
  }
  
  .custom-height::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
  }
  
  


.ezy__epqoverview2-gallery li {
   
    display: inline-flex  !important;
	margin: 5px;
    justify-content: center !important;
    align-items: center  !important;
    background-color: #f0e9e9;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 8px;
	margin-right: 30px;
    cursor: pointer;
    transition: border-color 0.3s ease; /* Optional: add transition for smooth effect */
}

/* Style for the active (selected) thumbnail */
.ezy__epqoverview2-gallery li.active-thumbnail {
    border: 2px solid rgb(75, 229, 91); /* Change the border color to yellow for the selected thumbnail */
}



.Mainimage{
	height: 400px;
    width: 400px;
	border-radius: 10px;
}

.Mainimageco{
	height: 400px;
    width: 100%;
	border-radius: 10px;
}

.margintop{
	/* margin-top: 100px; */
}

.hide{
	display: none;
	
 }


@media (max-width: 567px) {
	.imagesidestyle{
		width: 90px;
	 height: 80px;
 
 }
 

	.rowimage{
		display:block;
	}
	.Mainimage{
		width: 3250px !important;
		height: 350px;

		margin-left: 0px;
		border-radius: 10px;
	}

	.btnbut{
		background-color: #111111;
		width:100%;
	}
  
}

@media (max-width: 329px) {
	

	.rowimage{
		display:block;
	}
	.Mainimage{
		width: 300px !important;
		margin-left: 0px;
		border-radius: 10px;
	}

	.btnbut{
		background-color: #111111;
		width:100%;
	}
  
}


@media (max-width: 768px) {
	.hide {
		display: none !important;
	}

	.hided {
		display: block !important; /* Ensure elements are shown on smaller screens */
	}
}

@media (min-width: 768px) {
	.hided {
		display: none !important; /* Hide on larger screens */
	}
}


@media (max-width: 991px) {
	.margintopsec{
		margin-top: 100px;

	}
	.margintop {
	margin-top: 10px !important;
	/* background-color: #cadf11; */
	}

	

  
}




/* Define styles for screens 1024px to 1440px wide */
@media (min-width: 1024px) and (max-width: 1440px) {
	.sizemedia {
	  font-size: 18px;
	}
  
	.header {
	  font-size: 24px;
	}
  }
  


  @media (min-width: 1024px) and (max-width: 1440px) {
	.sizemedia {
	  font-size: 18px;
	}
  
	.header {
	  font-size: 24px;
	}
  }







  /* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .sizemedia {
    font-size: 14px;
  }

  .header {
    font-size: 20px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .sizemedia {
    font-size: 16px;
  }

  .header {
    font-size: 22px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .sizemedia {
    font-size: 17px;
  }

  .header {
    font-size: 23px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .sizemedia {
    font-size: 18px;
  }

  .header {
    font-size: 24px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1439.98px) {
  .sizemedia {
    font-size: 19px;
  }

  .header {
    font-size: 26px;
  }
}

/* 2K resolution (1440px and up) */
@media (min-width: 1440px) and (max-width: 1919.98px) {
  .sizemedia {
    font-size: 20px;
  }

  .header {
    font-size: 28px;
  }
}

/* 4K resolution (1920px and up) */
@media (min-width: 1920px) {
  .sizemedia {
    font-size: 22px;
  }

  .header {
    font-size: 30px;
  }
}
