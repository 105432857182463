
.margintop{
	margin-top: 100px;
}
.ezy__epcheckout6 {
	/* Bootstrap variables */
	--bs-body-color: #23262f;
	--bs-body-bg: rgb(255, 255, 255);

	/* Easy Frontend variables */
	--ezy-theme-color: rgb(13, 110, 253);
	--ezy-theme-color-rgb: 13, 110, 253;
	--ezy-secondary-color-rgb: 235, 235, 235;
	--ezy-button-color: #fff;
	--ezy-card-bg: #f5f7fd;

	background-color: var(--bs-body-bg);
	color: var(--bs-body-color);
	overflow: hidden;
	padding: 50px 0;
	position: relative;
}

/* Gray Block Style */
.gray .ezy__epcheckout6,
.ezy__epcheckout6.gray {
	/* Bootstrap variables */
	--bs-body-bg: rgb(246, 246, 246);

	/* Easy Frontend variables */
	--ezy-secondary-color-rgb: 230, 232, 236;
	--ezy-card-bg: rgb(255, 255, 255);
}

/* Dark Gray Block Style */
.dark-gray .ezy__epcheckout6,
.ezy__epcheckout6.dark-gray {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(30, 39, 53);

	/* Easy Frontend variables */
	--ezy-secondary-color-rgb: 38, 63, 99;
	--ezy-card-bg: rgb(11, 23, 39);
}


.ezy__epcheckout6-container{
    background-color: #e3e5ed;
    /* height: 100vh; */

    /* padding: 100px; */
}
/* Dark Block Style */
.dark .ezy__epcheckout6,
.ezy__epcheckout6.dark {
	/* Bootstrap variables */
	--bs-body-color: #ffffff;
	--bs-body-bg: rgb(11, 23, 39);

	/* Easy Frontend variables */
	--ezy-secondary-color-rgb: 38, 63, 99;
	--ezy-card-bg: rgb(30, 39, 53);
}

.ezy__epcheckout6 a {
	font-weight: 500;
	color: rgb(var(--ezy-theme-color-rgb));
	text-decoration: none;
}

.ezy__epcheckout6-heading a {
	font-size: 16px;
	line-height: 18px;
	color: var(--bs-body-color);
	text-decoration: none;
}

.ezy__epcheckout6-heading a:hover {
	color: rgb(var(--ezy-theme-color-rgb));
}

@media (min-width: 768px) {
	.ezy__epcheckout6-heading a {
		font-size: 17px;
		line-height: 19px;
	}
}

.ezy__epcheckout6-price {
	font-size: 20px;
	font-weight: bold;
	color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epcheckout6-title {
	font-size: 15px;
	color: var(--bs-body-color);
}

.ezy__epcheckout6-image {
	min-width: 50px;
	width: 50px;
	height: auto;
	background-color: rgb(var(--ezy-secondary-color-rgb));
	border: 1px solid rgba(var(--ezy-secondary-color-rgb), 1);
	border-radius: 4px;
	overflow: hidden;
}

@media (min-width: 768px) {
	.ezy__epcheckout6-image {
		min-width: 120px;
		width: 120px;
	}
}

.ezy__epcheckout6-qty {
	width: 144px;
}

.ezy__epcheckout6-qty .btn {
	font-size: 20px;
	font-weight: bold;
	color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epcheckout6-qty .btn:hover {
	background-color: rgb(var(--ezy-secondary-color-rgb));
}

.ezy__epcheckout6-qty .btn:first-child {
	border-radius: 50rem 0 0 50rem !important;
}

.ezy__epcheckout6-qty .btn:last-child {
	border-radius: 0 50rem 50rem 0 !important;
}

.ezy__epcheckout6-qty .form-control {
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	background-color: transparent;
	color: var(--bs-body-color);
	padding: 0;
}

.ezy__epcheckout6-qty .btn,
.ezy__epcheckout6-qty .form-control {
	border-color: rgb(var(--ezy-secondary-color-rgb));
	height: 36px;
}

.ezy__epcheckout6-btn,
.ezy__epcheckout6-btn-outline {
	font-size: 14px;
	height: 42px;
	min-width: 42px;
}

.ezy__epcheckout6-btn {
	border-color: rgb(var(--ezy-theme-color-rgb));
	background-color: rgb(var(--ezy-theme-color-rgb));
	color: var(--ezy-button-color);
}

.ezy__epcheckout6-btn-outline {
	border-color: rgb(var(--ezy-theme-color-rgb));
	background-color: transparent;
	color: rgb(var(--ezy-theme-color-rgb));
}

.ezy__epcheckout6-btn-outline:hover,
.ezy__epcheckout6-btn:hover {
	background-color: rgba(var(--ezy-theme-color-rgb), 0.9) !important;
	color: var(--ezy-button-color);
}

.ezy__epcheckout6-del {
	width: 38px;
	height: 38px;
	background-color: transparent;
	color: var(--ezy-theme-color);
}

.ezy__epcheckout6-del:hover {
	background-color: rgba(var(--ezy-secondary-color-rgb), 0.5);
	color: var(--ezy-theme-color);
}

.ezy__epcheckout6-card {
	background-color: var(--ezy-card-bg);
	border: none;
	border-radius: 10px;
}

.ezy__epcheckout6-hr {
	background-color: rgb(var(--ezy-secondary-color-rgb));
	opacity: 1;
}

.ezy__epcheckout6-promo-code .form-control {
	border-color: rgb(var(--ezy-secondary-color-rgb), 1);
	background-color: rgb(var(--ezy-secondary-color-rgb), 0.3);
	color: rgb(var(--bs-body-color));
	font-size: 14px;
	height: 38px;
}

.ezy__epcheckout6-content {
	font-size: 14px;
	color: var(--bs-body-color);
}

.ezy__epcheckout6-content a {
	color: var(--ezy-theme-color);
}

.ezy__epcheckout6-theme-color {
	color: rgba(var(--ezy-theme-color-rgb), 1);
}

.ezy__epcheckout6-delivery-card {
	border-color: rgb(var(--ezy-secondary-color-rgb), 1);
	background-color: rgb(var(--ezy-secondary-color-rgb), 0.3);
}



.margintop{
	margin-top: 150px;
}