.timeline-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
  }
  
  .timeline {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .step {
    position: relative;
    padding: 10px;
    background: lightgray;
    border-radius: 50px;
    width: 70px;
    height: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    transition: background 0.3s;
  }
  
  .step.active {
    background-color: #4caf50;
    color: white;
  }
  
  .form-step {
    margin-bottom: 20px;
  }
  
  .form-step input, .form-step select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .form-navigation {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
  }
  
  .sigCanvas {
    border: 1px solid #ccc;
    width: 100%;
  }
  
  button {
    margin-right: 10px;
  }

  /* .modalsign{
height: 800px;
  }
   */


   @media (max-width: 576px) {
   
    .step {
      position: relative;
      padding: 10px;
      background: lightgray;
      border-radius: 50px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      text-align: center;
  
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      transition: background 0.3s;
    }
  }