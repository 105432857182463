.hero-sectionhowcont {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../Pages/images/harbor.jpg") no-repeat center center / cover;
  color: white;
  padding: 100px 20px;
}

.contact-details-container {
  max-width: 1200px;
  margin-bottom: 50px;
  background: rgba(20, 19, 19, 0.4);
  border-radius: 12px;
  padding: 20px;
  text-align: left;
}

.company-name {
  font-size: 24px;
  font-weight: bold;
  color: #ffcc00;
}

.company-email,
.company-phone {
  margin: 10px 0;
}

.company-map {
  margin-top: 20px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.contact-container {
  max-width: 1200px;
  padding: 20px;
  background: rgba(20, 19, 19, 0.4);
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(222, 115, 115, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: background 0.3s ease-in-out;
  margin: 50px 0;
}

.contact-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #007bff;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.Email {
  color: #070707;
  text-align: left;
  margin-bottom: 5px;
}

.form-input, .select-dropdown {
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s;
  color: #070707 !important;
  background: rgba(255, 255, 255, 0.9);
}

.input-error {
  border-color: #ff5555;
}

.error-message {
  color: #ff5555;
  font-size: 14px;
}

.submit-button {
  background-color: #007bff;
  color: white;
  font-size: 18px;
  padding: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}



.react-tel-input .country-list  {
  background-color: #e8e3e3;
  color: #070707;
}