.butttonstyle {
  width: "100% " !important ;
  /* background-color: aquamarine; */
}

.orderimage {
  width: 100%;
  border-radius: 10px;
}

.margintopp {
  margin-top: 100px !important;
}

.active {
  font-weight: bold;
}

.nav-linkname {
  color: #000 !important;
}

.rounded-circle {
  height: 100px;
  width: 100px;
}

.btnsize {
  text-decoration: underline;
  cursor: pointer;
}

.btnsize :hover {
  transition: ease-in-out;

  text-decoration: underline;
}
